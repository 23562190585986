import { split, HttpLink } from '@apollo/client';
import DeadendLink from './DeadendLink';
import hasAuthTokenInHeader from '../infra/apollo/utils/hasAuthTokenInHeader';
import isOperationEmpty from '../infra/apollo/utils/isOperationEmpty';

// A conditional Endpoint Link
// Using the split function allows to send the request to one of
// two different Links, according to the result of a boolean check.

const EndpointLink = (urlBase) => {
  const checking = (operation) => {
    const hasToken = hasAuthTokenInHeader(operation.getContext().headers);
    const includeCloudDevices = operation.getContext().includeCloudDevices;
    const excludeCloudDevices = !hasToken || !includeCloudDevices;
    const requestedDevices = operation.getContext();
    const isCurrentlyEmpty = isOperationEmpty(operation);
    // Will be empty only when requestDevices=true and the operation requested only devices.
    return requestedDevices && excludeCloudDevices && isCurrentlyEmpty;
  };
  return split(
    checking,
    // When True
    new DeadendLink(),
    // When False
    new HttpLink({
      uri: urlBase,
      fetch
    })
  );
};

export default EndpointLink;

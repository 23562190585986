import hasAuthTokenInHeader from '../infra/apollo/utils/hasAuthTokenInHeader';
import IClientOsLink from '../infra/apollo/IClientOsLink';
import findFieldInOperation from '../utils/document/findFieldInOperation';
import removeFieldFromDocument from '../utils/document/removeFieldFromDocument';

const DEVICES_LABEL = 'devices';

class OfflineDeviceLink extends IClientOsLink {
  constructor() {
    super();
  }

  request(operation, forward) {
    const hasToken = hasAuthTokenInHeader(operation.getContext().headers);

    const isDeviceFound = findFieldInOperation(DEVICES_LABEL, operation);

    const includeCloudDevices = operation.getContext().includeCloudDevices;

    const excludeCloudDevices = !hasToken || !includeCloudDevices;

    operation.setContext({
      requestDevices: isDeviceFound
    });

    if (isDeviceFound && excludeCloudDevices) {
      const newQuery = removeFieldFromDocument(operation.query, DEVICES_LABEL);
      operation.query = newQuery;
    }

    return forward(operation).map((data) => {
      // TODO: Find a way to create unit testings for this
      if (isDeviceFound && excludeCloudDevices && !data.data[DEVICES_LABEL]) {
        data.data[DEVICES_LABEL] = null;
      }
      return data;
    });
  }
}

export default OfflineDeviceLink;

import { setContext } from '@apollo/client/link/context';

const CloudDeviceLink = (
  includeCloudDevicesCallback: () => Promise<boolean>
) => {
  return setContext(async () => {
    let includeCloudDevices: boolean;
    if (includeCloudDevicesCallback) {
      includeCloudDevices = await includeCloudDevicesCallback();
    } else {
      includeCloudDevices = true;
    }
    return {
      includeCloudDevices: includeCloudDevices
    };
  });
};

export default CloudDeviceLink;
